import React, { useEffect, useState } from "react";
import { Box, Divider, makeStyles } from "@material-ui/core";

import { useMessageContext } from "contexts/MessagesContext";

import UsuariosAPI from "services/api/axios-usuarios";

import Loading from "components/Loading";
import ModalTemplate from "components/ModalTemplate";
import PageTemplate from "components/PageTemplate";

import SaveIcon from "@material-ui/icons/Save";

import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";

const EditarUsuario = (props) => {
  const classes = useStyles();
  const [usuario, setUsuario] = useState(null);
  const [confirmaEmail, setConfirmaEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { id } = props.match.params;
  const [context, action] = useMessageContext();

  useEffect(() => {
    let isMounted = true;
    if (context.hasMessage) {
      action.hideMessage();
    }
    (async () => {
      try {
        setLoading(true);
        const dados = await UsuariosAPI.find(id);
        if (isMounted) {
          setUsuario(dados);
          setConfirmaEmail(dados.email);
        }
      } catch (error) {
        props.history.push("/usuarios");
        action.showMessage({ type: "error", content: error.message });
      } finally {
        if (isMounted) setLoading(false);
      }
    })();
    return () => {
      setLoading(false);
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, action, props.history]);

  const handleConfirmNewPassword = () => {
    setLoading(true);
    (async () => {
      try {
        await UsuariosAPI.resetPassword(formik.values.email);
        action.showToast({
          type: "success",
          content: "Instruções enviadas para o e-mail cadastrado.",
        });
      } catch (error) {
        action.showToast({ type: "error", content: error.message });
      } finally {
        setLoading(false);
      }
    })();
    setLoading(false);
    setOpenModal(false);
  };

  const handleSaveUser = async (values) => {
    action.hideMessage();
    action.hideToast();
    if (usuario.email !== confirmaEmail) {
      return action.showMessage({
        type: "error",
        content: "Os emails precisam ser iguais",
      });
    }
    try {
      setLoading(true);
      await UsuariosAPI.update(usuario.id, values);
      props.history.push("/usuarios");
      return action.showToast({
        type: "success",
        content: "Usuário atualizado com sucesso.",
      });
    } catch (error) {
      return action.showToast({
        type: "error",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: usuario,
    onSubmit: handleSaveUser,
  });

  return (
    <PageTemplate
      title={"Editar usuário"}
      buttonLabel="Salvar"
      buttonIcon={<SaveIcon />}
      onButtonClick={formik.handleSubmit}
    >
      {!usuario ? (
        <Loading />
      ) : (
        <>
          <Paper>
            <Grid container spacing={4} className={classes.content}>
              <Grid item xs={12} sm={1}>
                <FormGroup row>
                  <TextField
                    id="id"
                    label="Id"
                    variant="outlined"
                    required
                    value={usuario.id}
                    disabled
                    fullWidth
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormGroup row>
                  <TextField
                    id="nome"
                    label="Nome usuário"
                    variant="outlined"
                    required
                    disabled={loading}
                    value={formik.values?.nome}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup row>
                  <TextField
                    id="email"
                    label="E-mail"
                    variant="outlined"
                    required
                    disabled={loading}
                    value={formik.values?.email}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup row>
                  <TextField
                    id="confirmaEmail"
                    label="Confirmar e-mail"
                    variant="outlined"
                    required
                    disabled={loading}
                    value={confirmaEmail}
                    onChange={(e) => setConfirmaEmail(e.target.value)}
                    fullWidth
                  />
                </FormGroup>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  disabled={loading}
                  onClick={() => setOpenModal(true)}
                >
                  Gerar nova senha
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Modal
            className={classes.modal}
            open={openModal}
            BackdropComponent={Backdrop}
          >
            <ModalTemplate
              title="Gerar nova senha"
              onCloseModal={() => setOpenModal(false)}
            >
              <Grid container spacing={4} className={classes.content}>
                <Grid item xs={12}>
                  <Typography display="block" gutterBottom>
                    Uma nova senha será gerada e encaminhada para o e-mail{" "}
                    <strong>{formik.values?.email}</strong>.
                  </Typography>
                </Grid>
              </Grid>
              <Divider light />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={handleConfirmNewPassword}
                >
                  Confirmar
                </Button>
              </Box>
            </ModalTemplate>
          </Modal>
        </>
      )}
    </PageTemplate>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(4),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default EditarUsuario;
