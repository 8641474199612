import VerifiedIcon from "@material-ui/icons/VerifiedUser";

export const IsVerified = (props) => {
  const { value } = props;
  return (
    <VerifiedIcon
      htmlColor={"green"}
      fontSize="small"
      color={value ? "inherit" : "disabled"}
    />
  );
};
