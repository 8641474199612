import Header from "./Header";

import Grid from "@material-ui/core/Grid";
import { useMessageContext } from "contexts/MessagesContext";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const LoginPageTemplate = ({ children }) => {
  const [ctx, axn] = useMessageContext();

  return (
    <>
      <Header />
      <Snackbar
        open={ctx.hasToast}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={6000}
        onClose={axn.hideToast}
      >
        <Alert onClose={axn.hideToast} severity={ctx.toast.type}>
          {ctx.toast.content}
        </Alert>
      </Snackbar>
      <Grid
        alignItems="center"
        container
        direction="column"
        justify="center"
        spacing={0}
        style={{ minHeight: "100vh" }}
      >
        <Grid item>{children}</Grid>
      </Grid>
    </>
  );
};

export default LoginPageTemplate;
