import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const DefaultTable = (props) => {
  const {
    columns = [{ title: "Sem colunas" }],
    rows = [],
    loading = false,
    onClickEdit = null,
    onClickDelete = null,
    page = 0,
    count = 0,
    rowsPerPage = 5,
    onChangePage = null,
    onChangeRowsPerPage = null,
  } = props;

  const hasEditFn = Boolean(onClickEdit);
  const hasDeleteFn = Boolean(onClickDelete);
  const isEmptyTable = rows && rows.length < 1;
  const hasPageFooter = true; // provi //
  const hasManagementColumn = hasDeleteFn || hasEditFn;
  const colSpanSize = hasManagementColumn ? columns.length + 1 : columns.length;

  const handleDeleteItem = (data) => {
    if (onClickDelete) {
      onClickDelete(data);
    }
  };

  const handleEditItem = (data) => {
    if (onClickEdit) {
      onClickEdit(data);
    }
  };

  const handleChangePage = (_, page) => {
    if (onChangePage) {
      onChangePage(page);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(e.target.value);
    }
  };

  const LoadingTableRow = () => {
    return (
      <TableRow>
        <TableCell align="center" colSpan={colSpanSize}>
          <CircularProgress size={24} color="primary" />
        </TableCell>
      </TableRow>
    );
  };

  const EmptyTableRow = () => {
    return (
      <TableRow>
        <TableCell colSpan={colSpanSize}>
          Não existem dados a serem mostrados.
        </TableCell>
      </TableRow>
    );
  };

  const ManagementColumnHeader = () => {
    return <TableCell align="center">GERENCIAR</TableCell>;
  };

  const ManagementColumnCell = ({ rowData }) => {
    return (
      <TableCell align="center">
        {hasEditFn && (
          <IconButton size="small" onClick={() => handleEditItem(rowData)}>
            <EditIcon />
          </IconButton>
        )}
        {hasDeleteFn && (
          <IconButton size="small" onClick={() => handleDeleteItem(rowData)}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    );
  };

  function renderCellValue({ row, cell }) {
    const hasComponent = Boolean(cell.component);
    if (hasComponent) {
      return (
        <cell.component field={cell.field} value={row[cell.field]} data={row} />
      );
    } else {
      return row[cell.field];
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <StyledTableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={column.id || index} variant="head">
                {column.title ? column.title.toUpperCase() : null}
              </TableCell>
            ))}
            {hasManagementColumn && <ManagementColumnHeader />}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {loading && <LoadingTableRow />}
          {!loading && isEmptyTable ? <EmptyTableRow /> : null}
          {!loading && !isEmptyTable
            ? rows?.map((row, rowindex) => (
                <TableRow key={row.id || rowindex}>
                  {columns.map((cell, cellindex) => (
                    <TableCell key={cell.id || cellindex}>
                      {renderCellValue({ row, cell })}
                    </TableCell>
                  ))}
                  {hasManagementColumn && (
                    <ManagementColumnCell rowData={row} />
                  )}
                </TableRow>
              ))
            : null}
        </TableBody>
        {hasPageFooter && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

const StyledTableHead = withStyles((theme) => ({
  root: {
    border: `0px solid ${theme.palette.primary.main}`,
    borderTopWidth: "2px",
    borderBottomWidth: "2px",
  },
}))(TableHead);

export default DefaultTable;
