/* eslint-disable react/jsx-props-no-spreading */
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? children : <Redirect to={{ pathname: "/login" }} />
      }
    />
  );
};

export default PrivateRoute;
