import { initialState } from ".";
import * as actionTypes from "./action-types";

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MESSAGE:
      return { ...state, message: { ...action.payload }, hasMessage: true };
    case actionTypes.HIDE_MESSAGE:
      return { ...state, hasMessage: false };
    case actionTypes.SHOW_TOAST:
      return { ...state, toast: { ...action.payload }, hasToast: true };
    case actionTypes.HIDE_TOAST:
      return { ...state, hasToast: false };
  }
  return state;
};
