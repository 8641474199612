import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

import DocumentosAPI from "services/api/axios-documentos";
import IbgeAPI from "services/api/axios-ibge";

import PageTemplate from "components/PageTemplate";
import GeoFilter from "components/GeoFilter";
import DataTable from "components/Table";
import Loading from "components/Loading";
import FileInput from "components/FileInput";
import ModalTemplate from "components/ModalTemplate";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SaveIcon from "@material-ui/icons/Save";
import { filtrarPropriedades } from "utils/tabela-utils";
import { Add } from "@material-ui/icons";
import { withGeofilter } from "hoc/withGeofilter";
import { useMessageContext } from "contexts/MessagesContext";
import { useHistory } from "react-router";

const EditarDocumento = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const isUpSmScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { id } = props.match.params;
  const { fromFilter } = props;

  const { 1: action } = useMessageContext();

  const [registro, setRegistro] = useState(null);
  const [arquivos, setArquivos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [tituloDoc, setTituloDoc] = useState("");
  const [file, setFile] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (reload) setReload(false);
    (async () => {
      const dados = await DocumentosAPI.find(id);
      setRegistro(dados);
      setTituloDoc(dados.nome);
      fromFilter.setUf(dados.uf);
      fromFilter.setMunicipio(dados.municipio);
      fromFilter.setEsfera(dados.esfera);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  useEffect(() => {
    if (reload) setReload(false);
    setLoading(true);
    IbgeAPI.fetchUfs()
      .then((ufs) => {
        fromFilter.setUfs(ufs);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reload) setReload(false);
    if (fromFilter.uf && fromFilter.esfera === "Municipal") {
      setLoading(true);
      fromFilter.setMunicipio(null);
      IbgeAPI.fetchMunicipios(fromFilter.uf)
        .then((municipios) => {
          fromFilter.setMunicipios(municipios);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      fromFilter.setMunicipios([]);
      fromFilter.setMunicipio(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromFilter.uf, fromFilter.esfera]);

  useEffect(() => {
    if (reload) setReload(false);
    if (registro) {
      (async () => {
        try {
          setLoadingDocs(true);
          const data = await DocumentosAPI.getFiles(registro.id);
          setArquivos(() =>
            filtrarPropriedades(data.arquivos, ["created_at", "key"])
          );
        } catch (error) {
        } finally {
          setLoadingDocs(false);
        }
      })();
    }
    return () => {
      setArquivos([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registro, reload]);

  const handleAddNewDocument = async () => {
    setNewFile(file);
    setOpenModal(false);
  };

  const handleDelete = (registerId) => {
    setSelectedFileId(registerId);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDeleteFile = () => {
    (async () => {
      try {
        await DocumentosAPI.delete(selectedFileId);
        action.showToast({
          type: "success",
          content: "Arquivo deletado com sucesso",
        });
      } catch (error) {
        action.showToast({ type: "error", content: error?.message });
      } finally {
        setSelectedFileId(null);
        handleCloseConfirmDialog();
        setReload(true);
      }
    })();
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSaveDocument = async () => {
    try {
      setLoading(true);
      const preparedFormData = prepareFormDataToPayload({
        titulo: tituloDoc,
        municipio: fromFilter.municipio,
        uf: fromFilter.uf,
        esfera: fromFilter.esfera,
        arquivo: newFile,
      });
      await DocumentosAPI.update(id, preparedFormData);
      action.showToast({
        type: "success",
        content: "Documento atualizado com sucesso",
      });
    } catch (error) {
      action.showToast({ type: "error", content: error?.message });
    } finally {
      setLoading(false);
      history.push("/documentos");
    }
  };

  return (
    <PageTemplate
      title="Editar documento"
      buttonLabel="Salvar"
      buttonIcon={<SaveIcon />}
      onButtonClick={handleSaveDocument}
    >
      <Grid container spacing={0}>
        {!registro ? (
          <Grid item xs={12}>
            <Loading />
          </Grid>
        ) : (
          <Grid item container component={Paper} className={classes.content}>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <FormGroup row>
                  <TextField
                    id="documento"
                    label="Nome do documento"
                    variant="outlined"
                    required
                    value={tituloDoc}
                    onChange={(e) => setTituloDoc(e.target.value)}
                    fullWidth
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <GeoFilter
                  loading={loading}
                  listaUf={fromFilter.listaUf}
                  listaMunicipio={fromFilter.listaMunicipio}
                  onChangeUf={fromFilter.setUf}
                  uf={fromFilter.uf}
                  onChangeMunicipio={fromFilter.setMunicipio}
                  municipio={fromFilter.municipio}
                  esfera={fromFilter.esfera}
                  onChangeEsfera={fromFilter.setEsfera}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <FormGroup row>
                  <TextField
                    id="documento"
                    label="Arquivo atual"
                    variant="outlined"
                    disabled
                    value={registro?.arquivos[0]?.nome}
                    fullWidth
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                {newFile && (
                  <Box p={2} color="success.main" border={1} borderRadius={4}>
                    <Chip className={classes.chip} size="small" label="Novo" />
                    <Typography display="inline">{newFile.name}</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} variant="fullWidth" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <Typography component="span" variant="h6">
                  Arquivos vinculados ao registro
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              justify={isUpSmScreen ? "flex-end" : "flex-start"}
            >
              <Box p={1} alignItems="center">
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  disabled={loadingDocs}
                  onClick={() => setOpenModal(true)}
                  startIcon={<Add />}
                >
                  Adicionar novo arquivo
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={1}>
                <DataTable
                  data={arquivos}
                  resourcePath="/arquivos"
                  loading={loadingDocs}
                  handleDelete={handleDelete}
                  noEdit
                />
              </Box>
            </Grid>

            <Modal
              className={classes.modal}
              open={openModal}
              BackdropComponent={Backdrop}
            >
              <ModalTemplate
                title="Adicionar novo documento"
                onCloseModal={() => setOpenModal(false)}
              >
                <Grid container spacing={4} className={classes.content}>
                  <Grid item xs={12}>
                    <Typography paragraph>
                      O novo arquivo selecionado será incluído como arquivo
                      principal do documento.
                    </Typography>
                    <Grid item xs>
                      <FileInput file={file} onSelectFile={setFile} />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider light />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    onClick={handleAddNewDocument}
                  >
                    Confirmar
                  </Button>
                </Box>
              </ModalTemplate>
            </Modal>
          </Grid>
        )}
      </Grid>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmar exclusão de arquivo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Esta operação não poderá ser desfeita. Tem certeza que deseja
            continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialog}
            variant="contained"
            color="primary"
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmDeleteFile}
            variant="contained"
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </PageTemplate>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(4),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
  },
}));

export default withGeofilter(EditarDocumento);

function prepareFormDataToPayload({ titulo, municipio, uf, esfera, arquivo }) {
  const data = new FormData();
  data.append("nome", titulo);
  data.append("municipio", municipio);
  data.append("uf", uf);
  data.append("esfera", esfera);
  data.append("arquivo", arquivo);
  return data;
}
