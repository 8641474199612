import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const FileInput = ({ file, onSelectFile, disabled }) => {
  const onChangeFile = (e) => {
    if (!e.target.files) return;
    const selectedFile = Array.from(e.target.files);
    onSelectFile(selectedFile[0]);
  };

  return (
    <FormGroup row>
      <Box display="flex" alignItems="center">
        <input
          accept=".pdf"
          style={{ display: "none" }}
          id="document_file"
          type="file"
          onChange={onChangeFile}
          disabled={disabled}
        />
        <label htmlFor="document_file" style={{ marginRight: 8 }}>
          <Button
            disabled={disabled}
            variant="outlined"
            component="span"
            color="primary"
          >
            selecionar arquivo .pdf
          </Button>
        </label>
        <Typography variant="subtitle1">
          {file ? file.name : "Selecione o arquivo..."}
        </Typography>
      </Box>
    </FormGroup>
  );
};

export default FileInput;
