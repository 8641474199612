import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/";

import IbgeAPI from "services/api/axios-ibge";
import DocumentosAPI from "services/api/axios-documentos";

import { withGeofilter } from "hoc/withGeofilter";
import { useMessageContext } from "contexts/MessagesContext";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import FileInput from "components/FileInput";
import GeoFilter from "components/GeoFilter";
import ModalTemplate from "components/ModalTemplate";

const NovoDocumento = (props) => {
  const classes = useStyles();
  const modalIsMounted = useRef();

  const { 1: action } = useMessageContext();
  const { closeButtonAction, fromFilter, actionOnCreate } = props;

  const [loading, setLoading] = useState(false);
  const [tituloDoc, setTituloDoc] = useState(null);
  const [file, setFile] = useState();

  useEffect(() => {
    modalIsMounted.current = true;
    setLoading(true);
    IbgeAPI.fetchUfs()
      .then((ufs) => {
        fromFilter.setUfs(ufs);
      })
      .finally(() => {
        if (modalIsMounted.current) setLoading(false);
      });
    return () => {
      modalIsMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fromFilter.uf && fromFilter.esfera === "Municipal") {
      setLoading(true);
      fromFilter.setMunicipio(null);
      IbgeAPI.fetchMunicipios(fromFilter.uf).then((municipios) =>
        fromFilter.setMunicipios(municipios)
      );
      if (modalIsMounted.current) setLoading(false);
    } else {
      fromFilter.setMunicipios([]);
      fromFilter.setMunicipio(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromFilter.uf, fromFilter.esfera]);

  const saveDataHandler = async () => {
    const preparedFormData = prepareFormDataToPayload({
      titulo: tituloDoc,
      municipio: fromFilter.municipio,
      uf: fromFilter.uf,
      esfera: fromFilter.esfera,
      arquivo: file,
    });
    try {
      setLoading(true);
      await DocumentosAPI.create(preparedFormData);
      action.showToast({
        type: "success",
        content: "Documento criado com sucesso",
      });
    } catch (error) {
      action.showToast({ type: "error", content: error?.message });
    } finally {
      setLoading(false);
      actionOnCreate();
      closeButtonAction();
    }
  };

  return (
    <ModalTemplate title="Novo Documento" onCloseModal={closeButtonAction}>
      <Grid
        container
        direction="column"
        spacing={4}
        className={classes.content}
      >
        {/* <Grid item xs={12} container> */}
        <Grid item xs={12}>
          <Typography display="block" gutterBottom>
            Abrangência
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <GeoFilter
              loading={loading}
              listaUf={fromFilter.listaUf}
              listaMunicipio={fromFilter.listaMunicipio}
              onChangeUf={fromFilter.setUf}
              uf={fromFilter.uf}
              onChangeMunicipio={fromFilter.setMunicipio}
              municipio={fromFilter.municipio}
              esfera={fromFilter.esfera}
              onChangeEsfera={fromFilter.setEsfera}
              autoFocus
            />
          </FormGroup>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={12}>
          <FormGroup row>
            <TextField
              className={classes.input}
              id="documento"
              label="Nome do documento"
              variant="outlined"
              required
              disabled={loading}
              value={tituloDoc || ""}
              onChange={(e) => setTituloDoc(e.target.value)}
              fullWidth
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FileInput file={file} onSelectFile={setFile} disabled={loading} />
        </Grid>
        <Divider light />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={saveDataHandler}
          >
            Salvar
          </Button>
        </Box>
      </Grid>
    </ModalTemplate>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
  input: {
    backgroundColor: "#FFF",
  },
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default withGeofilter(NovoDocumento);

function prepareFormDataToPayload({ titulo, municipio, uf, esfera, arquivo }) {
  const data = new FormData();
  data.append("nome", titulo);
  data.append("municipio", municipio);
  data.append("uf", uf);
  data.append("esfera", esfera);
  data.append("arquivo", arquivo);
  return data;
}
