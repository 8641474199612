import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";

import UsuariosAPI from "services/api/axios-usuarios";

import { useQuery } from "hooks/useQuery";
import { useMessageContext } from "contexts/MessagesContext";

import OSCPanelLogo from "assets/images/logo-painel.png";

import LoginPageTemplate from "components/LoginPageTemplate";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

const resetPassValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("E-mail inválido.")
    .required("Informe o e-mail de acesso"),
  password: Yup.string()
    .min(8, "Sua senha precisa ter no mínimo 8 caracteres")
    .required("Você precisa digitar uma nova senha"),
  confirmPassword: Yup.string().required(
    "Você precisa confirmar sua nova senhas"
  ),
  token: Yup.string().required("Informe o token recebido por e-mail"),
});

const ResetSenha = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const [state, actions] = useMessageContext();

  const [tokenFromUrl, setTokenFromUrl] = useState(false);
  const [userFromUrl, setUserFromUrl] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      token: "",
    },
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
    validationSchema: resetPassValidationSchema,
  });

  useEffect(() => {
    const token = query.get("token");
    const email = query.get("email");

    if (token) {
      setTokenFromUrl(true);
      formik.setFieldValue("token", token);
    }
    if (email) {
      formik.setFieldValue("email", email);
      setUserFromUrl(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitForm = async (values) => {
    const { password, confirmPassword, email, token } = formik.values;
    if (password === confirmPassword) {
      try {
        setLoading(true);
        await UsuariosAPI.setNewPassword({ password, email, token });
        actions.showMessage({
          type: "success",
          content: "Nova senha cadastrada com sucesso!",
        });
        history.push("/login");
      } catch (error) {
        actions.showMessage({
          type: "error",
          content: error.message,
        });
      } finally {
        setLoading(false);
      }
    } else {
      actions.showMessage({
        type: "error",
        content: "As senhas precisam ser iguais",
      });
    }
  };

  const renderLoginMessage = () => {
    return (
      <Typography variant="subtitle1">
        Seja bem vindo! Preencha os campos abaixo:
      </Typography>
    );
  };

  return (
    <>
      <LoginPageTemplate>
        <Grid
          container
          component={Paper}
          spacing={2}
          variant="outlined"
          className={classes.paper}
        >
          <Grid item xs={12} container justify="center">
            <Box py={2} display="flex" justifyContent="center">
              <img src={OSCPanelLogo} alt="Logo painel OSC Legal" />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="fullWidth" orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Box py={2} display="flex" justifyContent="center">
              <Typography variant="subtitle1">
                {renderLoginMessage()}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            component="form"
            // onSubmit={formik.handleSubmit}
          >
            <Grid item xs={12}>
              <TextField
                disabled={loading || tokenFromUrl}
                id="token"
                label="Token"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.token}
                error={formik.touched.token && Boolean(formik.errors.token)}
                helperText={formik.touched.token && formik.errors.token}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={loading || userFromUrl}
                id="email"
                type="email"
                label="E-mail de cadastro"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={loading}
                id="password"
                type="password"
                label="Digite uma nova senha"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={loading}
                id="confirmPassword"
                type="password"
                label="Confirme sua nova senha"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Collapse in={state.hasMessage}>
                <Alert
                  severity={state.message.type}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={actions.hideMessage}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {state.message.content}
                </Alert>
              </Collapse>
            </Grid>

            <Grid item xs={12} container justify="space-between">
              <Button
                disabled={loading}
                variant="contained"
                size="large"
                color="primary"
                onClick={formik.handleSubmit}
              >
                {loading ? <>Carregando</> : <>Confirmar</>}
              </Button>
              <Button
                disabled={loading}
                variant="default"
                color="primary"
                onClick={() => history.push("/login")}
              >
                Voltar para o login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </LoginPageTemplate>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    maxWidth: 540,
  },
}));

export default ResetSenha;
