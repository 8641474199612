import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import { Collapse, IconButton, Snackbar } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useMessageContext } from "contexts/MessagesContext";

/**
 * Componente de página padrão do app
 * @param title (requerido) Texto a ser exibido como título da página
 * @param children O que vai ser renderizado após o título
 * @param buttonLabel Se informado, cria um botão na página (lado superior direito)
 * @param onButtonClick Ação que o botão da página executa quando chamado
 * @param buttonComponent Use para passar o componente "Link" do react-router-dom
 * @param buttonLink Destino para o qual o botão aponta caso seja um "Link"
 * @param buttonIcon Ícone exibido no botão
 */
const PageTemplate = (props) => {
  const theme = useTheme();
  const isUpSmScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [ctx, axn] = useMessageContext();

  const {
    title,
    buttonComponent,
    buttonDisabled,
    onButtonClick,
    buttonLabel,
    buttonLink,
    buttonIcon,
    children,
  } = props;

  return (
    <>
      <Grid container>
        <Snackbar
          open={ctx.hasToast}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={6000}
          onClose={axn.hideToast}
        >
          <Alert onClose={axn.hideToast} severity={ctx.toast.type}>
            {ctx.toast.content}
          </Alert>
        </Snackbar>
        <Grid item xs={12} sm={8}>
          <Typography variant="h3" component="h1" gutterBottom>
            {title}
          </Typography>
        </Grid>
        {!buttonLabel ? null : buttonComponent ? (
          <Grid
            item
            container
            xs={12}
            sm={4}
            alignItems="center"
            justify={isUpSmScreen ? "flex-end" : "flex-start"}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={buttonIcon}
              component={buttonComponent}
              to={buttonLink}
              disabled={buttonDisabled}
            >
              {buttonLabel}
            </Button>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={12}
            sm={4}
            alignItems="center"
            justify={isUpSmScreen ? "flex-end" : "flex-start"}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={buttonIcon}
              onClick={onButtonClick}
              disabled={buttonDisabled}
            >
              {buttonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
      <Collapse in={ctx.hasMessage}>
        <Alert
          severity={ctx.message.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={axn.hideMessage}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {ctx.message.content}
        </Alert>
      </Collapse>
      <div style={{ marginTop: "16px", width: "100%" }}>{children}</div>
    </>
  );
};

export default PageTemplate;
