import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Loading from "components/Loading";
import Box from "@material-ui/core/Box";

import EditIcon from "@material-ui/icons/Edit";
import { TableFooter, TablePagination } from "@material-ui/core";

/**
 * Componente de tabela autônomo
 * @param data Array de objetos que serão apresentados na tabela.
 * @param loading Gerencia o estado da tabela (carregando).
 * @param resourcePath Caminho para link de edição de recurso. Quando informado, mostra a coluna "Gerenciar". Nota: o objeto precisa possuir uma propriedade "id"
 *
 */
const DataTable = (props) => {
  const [columns, setColumns] = useState([]);
  const classes = useStyles();

  const {
    data,
    loading,
    resourcePath,
    handleDelete,
    noEdit,
    noDelete,
    rowsPerPage,
    page,
  } = props;

  useEffect(() => {
    if (data && data.length > 0) {
      setColumns(Object.keys(data[0]));
    }
  }, [data]);

  const handleOnDelete = (id) => {
    handleDelete(id);
  };

  const ManageTable = ({ entry }) => {
    return (
      <>
        <StyledTableCell key="default-data" align="right">
          {!noEdit && (
            <IconButton
              size="small"
              className={classes.button}
              component={Link}
              to={{
                pathname: `${resourcePath}/${entry.id}/editar`,
                state: { dados: entry },
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {!noDelete && (
            <IconButton
              size="small"
              className={classes.button}
              onClick={() => handleOnDelete(entry.id)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </StyledTableCell>
      </>
    );
  };

  return loading ? (
    <Loading />
  ) : !data || data.length === 0 ? (
    <Paper>
      <Box p={2}>Nenhum dado encontrado.</Box>
    </Paper>
  ) : (
    <TableContainer component={Paper}>
      <StyledTable aria-label="customized table">
        <StyledTableHead>
          <TableRow>
            {columns?.map((title, index) => (
              <StyledTableCell key={`${index}-${title}`}>
                {title.toUpperCase()}
              </StyledTableCell>
            ))}
            {resourcePath && (
              <StyledTableCell key="default-heading" align="right">
                GERENCIAR
              </StyledTableCell>
            )}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {data?.map((entry, index) => (
            <TableRow key={index}>
              {columns?.map((column, index) => (
                <StyledTableCell key={column}>
                  {entry[column] !== "null" ? entry[column] : "-"}
                </StyledTableCell>
              ))}
              {resourcePath && <ManageTable entry={entry} {...props} />}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={data.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              // onChangePage={handleChangePage}
              // onChangeRowsPerPage={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </StyledTable>
    </TableContainer>
  );
};

DataTable.defaultProps = {
  data: null,
  rowsPerPage: 5,
  page: 0,
};

// #region Estilos
const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableHead = withStyles((theme) => ({
  root: {
    border: `0px solid ${theme.palette.primary.main}`,
    borderTopWidth: "2px",
    borderBottomWidth: "2px",
  },
}))(TableHead);

const StyledTable = withStyles((theme) => ({
  root: {
    minWidth: 700,
  },
}))(Table);

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

//#endregion

export default DataTable;
