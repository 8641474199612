import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import MenuCard from "components/MenuCard";
import PageTemplate from "components/PageTemplate";

import DocumentosImg from "assets/images/documentos.jpeg";
import UsuariosImg from "assets/images/usuarios.jpeg";
import { ROUTE_PATH as RP } from "config/constants";

const Painel = () => {
  return (
    <PageTemplate title="Painel de controle">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" gutterBottom>
            Seja bem vindo ao painel de controle do sistema OSC Legal. Selecione
            a opção no menu à esquerda ou nos cards abaixo.
          </Typography>
        </Grid>
        <Grid item container spacing={2}>
          <MenuCard
            title="Documentos"
            image={DocumentosImg}
            buttonComponent={Link}
            to={RP.DOCUMENTOS}
          >
            Acesso à lista de documentos cadastrados na base de dados.
          </MenuCard>
          <MenuCard
            title="Usuários"
            image={UsuariosImg}
            buttonComponent={Link}
            to={RP.USUARIOS}
          >
            Administrar usuários cadastrados no sistema .
          </MenuCard>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Painel;
