export const filtrarPropriedades = (data, colunas) => {
  const filtered = data.map((entry) => {
    const cp = Object.assign({}, entry);
    colunas.forEach((item) => {
      delete cp[item];
    });
    return cp;
  });
  return filtered;
};
