import React, { useReducer, useRef } from "react";

const initialValues = {
  listaUf: [],
  listaMunicipio: [],
  uf: null,
  municipio: null,
  esfera: null,
};

const actionTypes = {
  setUf: "setUf",
  clearUf: "clearUf",
  setMunicipio: "setMunicipio",
  clearMunicipio: "clearMunicipio",
  setListaUf: "setListaUf",
  setListaMun: "setListaMunicipio",
  setEsfera: "setEsfera",
};

const actionBuilder = (dispatch) => {
  return {
    setUfs: (payload) => dispatch({ type: actionTypes.setListaUf, payload }),
    //prettier-ignore
    setMunicipios: (payload) => dispatch({type: actionTypes.setListaMun, payload  }),
    setUf: (payload) => dispatch({ type: actionTypes.setUf, payload }),
    clearUf: () => dispatch({ type: actionTypes.clearUf }),
    //prettier-ignore
    setMunicipio: (payload) => dispatch({ type: actionTypes.setMunicipio, payload }),
    clearMunicipio: () => dispatch({ type: actionTypes.clearMunicipio }),
    setEsfera: (payload) => dispatch({ type: actionTypes.setEsfera, payload }),
  };
};

const reducer = (state = initialValues, action) => {
  switch (action.type) {
    case actionTypes.setListaUf:
      return { ...state, listaUf: action.payload };
    case actionTypes.setListaMun:
      return { ...state, listaMunicipio: action.payload };
    case actionTypes.setUf:
      return { ...state, uf: action.payload };
    case actionTypes.clearUf:
      return { ...state, uf: null };
    case actionTypes.setMunicipio:
      return { ...state, municipio: action.payload };
    case actionTypes.clearMunicipio:
      return { ...state, municipio: null };
    case actionTypes.setEsfera:
      return { ...state, esfera: action.payload };
  }
  return state;
};

export const withGeofilter = (Consumer) => (props) => {
  const [state, dispatch] = useReducer(reducer, initialValues);
  const actions = useRef(actionBuilder(dispatch));

  return <Consumer fromFilter={{ ...state, ...actions.current }} {...props} />;
};
