import * as type from "./action-types";

export const actionBuilder = (dispatch) => {
  return {
    showMessage: (payload) => dispatch({ type: type.SHOW_MESSAGE, payload }),
    showToast: (payload) => dispatch({ type: type.SHOW_TOAST, payload }),
    hideMessage: () => dispatch({ type: type.HIDE_MESSAGE }),
    hideToast: () => dispatch({ type: type.HIDE_TOAST }),
  };
};
