import { useEffect, useMemo, useState } from "react";

const useLazySearch = ({ delay = 500 }) => {
  const [term, setTerm] = useState("");
  const [debounced, setDebouncedTerm] = useState("");

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(term);
    }, delay);
    return () => {
      clearTimeout(timerId);
    };
  }, [term, delay]);

  return useMemo(
    () => ({
      term,
      debounced,
      setTerm,
    }),
    [term, debounced]
  );
};

export default useLazySearch;
