import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalThemeProvider from "styles/GlobalStyleProvider";

import AuthAPI from "services/api/axios-auth";
import { useMessageContext } from "contexts/MessagesContext";

import { ROUTE_PATH as RP } from "config/constants";

import DashboardRouter from "routes/DashboardRouter";
import PrivateRoute from "routes/PrivateRoute";
import Login from "pages/Login";
import session from "services/storage/session-storage";
import ResetSenha from "./ResetSenha";
import UsuariosAPI from "services/api/axios-usuarios";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [state, actions] = useMessageContext();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    const data = session.getToken();
    if (data) {
      setIsLoggedIn(true);
    }
    setLoading(false);
  }, []);

  const handleLogin = async (credentials) => {
    const { email, senha } = credentials;
    if (!email || !senha) {
      return actions.showMessage({
        type: "info",
        content: "Informe suas credenciais corretamente.",
      });
    }
    try {
      setLoading(true);
      const response = await AuthAPI.authenticate(credentials);
      if (response.token) {
        session.setToken(response.token);
        setIsLoggedIn(true);
        actions.hideMessage();
      }
    } catch (error) {
      actions.showMessage({
        type: "error",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetPass = async (email) => {
    if (!email) {
      return actions.showMessage({
        type: "info",
        content: "Informe seu usuário de acesso.",
      });
    }
    try {
      setLoading(true);
      await UsuariosAPI.resetPassword(email);
      actions.showMessage({
        type: "success",
        content: "Verifique sua caixa de mensagens.",
      });
    } catch (error) {
      actions.showMessage({
        type: "error",
        content:
          "Ocorreu um erro na solicitação. Entre em contato com o administrador do sistema.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    session.clearToken();
  };

  return (
    <>
      <GlobalThemeProvider>
        <CssBaseline />
        <div className={classes.root}>
          <BrowserRouter>
            <Switch>
              <Route exact path={RP.LOGIN}>
                {isLoggedIn ? (
                  <Redirect to={RP.PAINEL} />
                ) : (
                  <Login
                    loading={loading}
                    handleLoginButton={handleLogin}
                    handleRecoveryButton={handleResetPass}
                    showAlert={state.hasMessage}
                    alertType={state.message.type}
                    message={state.message.content}
                    onCloseAlert={actions.hideMessage}
                  />
                )}
              </Route>
              <Route exact path={RP.RESET_SENHA}>
                {isLoggedIn ? <Redirect to={RP.PAINEL} /> : <ResetSenha />}
              </Route>
              <PrivateRoute isLoggedIn={isLoggedIn} path={RP.ROOT}>
                <DashboardRouter logoutAction={handleLogout} />
              </PrivateRoute>
            </Switch>
          </BrowserRouter>
        </div>
      </GlobalThemeProvider>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  main: {
    flexGrow: 1,
    padding: theme.spacing(6),
  },
}));

export default App;
