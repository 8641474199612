/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import {
  extrairUfRegistros,
  extrairMunicipioRegistros,
} from "utils/registros-utils";

import DocumentosAPI from "services/api/axios-documentos";

import AddCircleIcon from "@material-ui/icons/AddCircle";

import Backdrop from "@material-ui/core/Backdrop";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import GeoFilter from "components/GeoFilter";
import PageTemplate from "components/PageTemplate";

import NovoDocumento from "pages/NovoDocumento";
import { ROUTE_PATH } from "config/constants";
import { withGeofilter } from "hoc/withGeofilter";
import { useMessageContext } from "contexts/MessagesContext";
import DefaultTable from "components/DefaultTable";
import { documentosColumns } from "config/documentos-table-schema";
import { useHistory } from "react-router";
import useLazySearch from "hooks/useLazySearch";

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#FFF",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Documentos = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useRef();
  const theme = useTheme();
  const isUpSmScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [updateScreen, setUpdateScreen] = useState(false);

  const { 1: action } = useMessageContext();
  const { term, debounced, setTerm } = useLazySearch({ delay: 1000 });

  const [openModal, setOpenModal] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);

  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);

  const { fromFilter } = props;

  const fetchDocuments = async () => {
    try {
      setLoading(() => true);
      const data = await DocumentosAPI.findAll({
        page: pagina,
        limit: linhasPorPagina,
        search: debounced ? debounced : null,
      });
      if (isMounted.current) {
        setRegistros(data);
        setLoading(() => false);
      }
    } catch (error) {
      if (isMounted.current) {
        action.showToast({ type: "error", content: error?.message });
      }
    } finally {
      if (isMounted.current) setLoading(() => false);
    }
  };

  useEffect(() => {
    if (updateScreen) {
      fetchDocuments();
      setUpdateScreen(false);
    }
  }, [updateScreen]);

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      fetchDocuments();
    }
    return () => {
      isMounted.current = false;
    };
  }, [action, pagina, linhasPorPagina, debounced]);

  useEffect(() => {
    if (registros?.documentos?.length > 0) {
      fromFilter.setUfs(extrairUfRegistros(registros?.documentos));
      if (fromFilter.uf) {
        fromFilter.clearMunicipio();
        fromFilter.setMunicipios(
          extrairMunicipioRegistros(fromFilter.uf, registros?.documentos)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registros, fromFilter.uf]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleDelete = (registerId) => {
    setSelectedDocId(registerId.id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    (async () => {
      try {
        await DocumentosAPI.delete(selectedDocId);
        action.showToast({
          type: "success",
          content: "Documento deletado com sucesso",
        });
        fetchDocuments();
      } catch (error) {
        action.showToast({ type: "error", content: error?.message });
      } finally {
        setSelectedDocId(null);
        handleCloseConfirmDialog();
      }
    })();
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleEditDocumento = (documentoData) => {
    const { id } = documentoData;
    history.push(`${ROUTE_PATH.DOCUMENTOS}/${id}/editar`, {
      dados: documentoData,
    });
  };

  return (
    <PageTemplate
      title="Documentos"
      buttonIcon={<AddCircleIcon />}
      buttonLabel="Novo documento"
      onButtonClick={handleOpenModal}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" gutterBottom>
            Filtrar documentos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormGroup>
            <TextField
              className={classes.input}
              id="documento"
              label="Buscar por documento"
              variant="outlined"
              onChange={(e) => setTerm(e.target.value)}
              value={term}
              fullWidth
            />
          </FormGroup>
        </Grid>
        {isUpSmScreen && (
          <Grid item container justify="center" xs={1}>
            <Divider variant="middle" orientation="vertical" light />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <GeoFilter
            // FIXME Forçando desabilitar campos!!
            // Componente precisa ser refatorado
            loading={true}
            // ----------------------------
            listaUf={fromFilter.listaUf}
            listaMunicipio={fromFilter.listaMunicipio}
            onChangeUf={fromFilter.setUf}
            uf={fromFilter.uf}
            onChangeMunicipio={fromFilter.setMunicipio}
            municipio={fromFilter.municipio}
            esfera={fromFilter.esfera}
            onChangeEsfera={fromFilter.setEsfera}
          />
        </Grid>
        <Grid item xs={12}>
          <DefaultTable
            loading={loading}
            columns={documentosColumns}
            rows={registros?.documentos}
            onClickDelete={handleDelete}
            onClickEdit={handleEditDocumento}
            page={pagina}
            count={registros?.total_documentos}
            rowsPerPage={linhasPorPagina}
            onChangePage={setPagina}
            onChangeRowsPerPage={setLinhasPorPagina}
          />
        </Grid>
      </Grid>
      <Modal
        className={classes.modal}
        open={openModal}
        BackdropComponent={Backdrop}
      >
        <NovoDocumento
          closeButtonAction={() => setOpenModal((prev) => !prev)}
          actionOnCreate={() => setUpdateScreen(true)}
        />
      </Modal>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmar exclusão de documento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Esta operação não poderá ser desfeita. Tem certeza que deseja
            continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialog}
            variant="contained"
            color="primary"
            autoFocus
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </PageTemplate>
  );
};

export default withGeofilter(Documentos);
