import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#FF4136",
        light: "#FF736B",
        dark: "#D10B00",
        contrastText: "#FFF",
      },
      secondary: {
        main: "#FFF",
        light: "#FF736B",
        dark: "#D10B00",
        contrastText: "#FF4136",
      },
      text: {},
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundColor: "#F7F9F9",
          },
        },
      },
    },
  },
  ptBR
);

function GlobalThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default GlobalThemeProvider;
