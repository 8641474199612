import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";

import UsuariosAPI from "services/api/axios-usuarios";
import { ROUTE_PATH } from "config/constants";

import AddCircleIcon from "@material-ui/icons/AddCircle";

import FormGroup from "@material-ui/core/FormGroup";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import PageTemplate from "components/PageTemplate";

import NovoUsuario from "pages/NovoUsuario";
import { useMessageContext } from "contexts/MessagesContext";
import DefaultTable from "components/DefaultTable";
import { usuariosColumns } from "config/usuarios-table-schema";
import { useHistory } from "react-router";
import useLazySearch from "hooks/useLazySearch";

const useStyles = makeStyles((theme) => ({
  field: {
    backgroundColor: "#FFF",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Usuarios = () => {
  const history = useHistory();
  const classes = useStyles();
  const isMounted = useRef();
  const totalUsuarios = useRef();

  const { 1: action } = useMessageContext();
  const { term, debounced, setTerm } = useLazySearch({ delay: 1000 });

  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [updateScreen, setUpdateScreen] = useState(false);

  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await UsuariosAPI.findAll({
        page: pagina,
        limit: linhasPorPagina,
        search: debounced ? debounced : null,
      });
      if (isMounted.current) setUsuarios(response.usuarios);
      if (isMounted.current) totalUsuarios.current = response.total_usuarios;
    } catch (error) {
      action.showToast({ type: "error", content: error.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (updateScreen) {
      fetchUsers();
      setUpdateScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateScreen]);

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      fetchUsers();
    }
    return () => {
      setLoading(false);
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, pagina, linhasPorPagina, debounced]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEditUsuario = (usuarioData) => {
    const { id } = usuarioData;
    history.push(`${ROUTE_PATH.USUARIOS}/${id}/editar`, { dados: usuarioData });
  };

  const handleDeleteUsuario = async (usuarioData) => {
    const { id } = usuarioData;
    action.hideToast();
    const confirm = window.confirm("Confirmar deletar usuário?");
    if (confirm) {
      try {
        setLoading(true);
        await UsuariosAPI.delete(id);
        await fetchUsers();
        action.showToast({
          type: "success",
          content: "Usuário deletado com sucesso",
        });
      } catch (error) {
        action.showToast({
          type: "error",
          content: "Usuário deletado com sucesso",
        });
      } finally {
        setPagina(0);
        setLoading(false);
      }
    }
  };

  return (
    <PageTemplate
      title="Usuários"
      buttonIcon={<AddCircleIcon />}
      buttonLabel="Novo usuário"
      onButtonClick={handleOpenModal}
      buttonDisabled={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" gutterBottom>
            Filtrar usuários
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormGroup>
            <TextField
              className={classes.field}
              fullWidth
              id="documento"
              label="Buscar por nome"
              variant="outlined"
              onChange={(e) => setTerm(e.target.value)}
              value={term}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <DefaultTable
            loading={loading}
            columns={usuariosColumns}
            rows={usuarios}
            onClickDelete={handleDeleteUsuario}
            onClickEdit={handleEditUsuario}
            page={pagina}
            count={totalUsuarios.current}
            rowsPerPage={linhasPorPagina}
            onChangePage={setPagina}
            onChangeRowsPerPage={setLinhasPorPagina}
          />
        </Grid>
      </Grid>
      <Modal
        className={classes.modal}
        open={openModal}
        BackdropComponent={Backdrop}
      >
        <NovoUsuario
          resetScreen={() => setUpdateScreen(true)}
          closeButtonAction={() => setOpenModal((prev) => !prev)}
        />
      </Modal>
    </PageTemplate>
  );
};

export default Usuarios;
