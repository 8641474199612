import { Grid, withStyles } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const GeoFilter = (props) => {
  const {
    loading,
    listaUf,
    listaMunicipio,
    onChangeUf,
    onChangeMunicipio,
    onChangeEsfera,
    uf,
    municipio,
    esfera,
    autoFocus,
  } = props;

  const handleSelectEsfera = (valor) => {
    if (valor === "Federal") {
      onChangeUf(null);
      onChangeMunicipio(null);
    } else if (valor === "Estadual") {
      onChangeMunicipio(null);
    }
    onChangeEsfera(valor);
  };

  const handleSelectUf = (valor) => {
    onChangeUf(valor);
  };

  const handleSelectMunicipio = (valor) => {
    onChangeMunicipio(valor);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          autoHighlight
          disableClearable
          fullWidth
          getOptionLabel={(option) => option}
          id="esfera"
          onChange={(_, value) => handleSelectEsfera(value)}
          options={["Federal", "Estadual", "Municipal"]}
          value={esfera}
          disabled={loading}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              autoComplete="false"
              label="Esfera"
              variant="outlined"
              autoFocus={autoFocus}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          autoHighlight
          disableClearable
          disabled={!esfera || esfera === "Federal" || loading}
          fullWidth
          filterSelectedOptions
          getOptionLabel={(option) => (option ? option : "Selecione...")}
          id="uf"
          loading={loading}
          loadingText="Aguarde..."
          noOptionsText="Vazio"
          onChange={(_, value) => handleSelectUf(value)}
          options={listaUf}
          value={uf}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              autoComplete="false"
              label="UF"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          autoHighlight
          disableClearable
          disabled={esfera !== "Municipal" || loading}
          filterSelectedOptions
          fullWidth
          getOptionLabel={(option) => (option ? option : "Selecione...")}
          id="municipio"
          loading={loading}
          loadingText="Aguarde..."
          noOptionsText="Nenhum resultado"
          onChange={(_, value) => handleSelectMunicipio(value)}
          options={listaMunicipio}
          value={municipio}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              autoComplete="false"
              label="Município"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const StyledTextField = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
  },
}))(TextField);

export default GeoFilter;
