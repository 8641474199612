import React from "react";
import ReactDOM from "react-dom";
import { MessageContextProvider } from "contexts/MessagesContext";
// import { createMirageServer } from "services/mirage-server";

import App from "./pages/App";

// const environment = process.env.NODE_ENV;
// if (environment !== "production") {
//   createMirageServer({ environment });
// }

ReactDOM.render(
  <React.StrictMode>
    <MessageContextProvider>
      <App />
    </MessageContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
