import axiosInstance from "./axios-instance";
import { API_URLS } from "config/constants";
import session from "services/storage/session-storage";

class AxiosDocumentosService {
  constructor(instance) {
    this.axiosInstance = instance;
  }

  findAll(terms) {
    const config = terms ? { params: terms } : null;
    return this.axiosInstance
      .get(API_URLS.DOCUMENTOS, config)
      .then((response) => response.data);
  }

  find(id) {
    return this.axiosInstance
      .get(`${API_URLS.DOCUMENTOS}/${id}`)
      .then((response) => response.data);
  }

  create(data) {
    const token = `Bearer ${session.getToken()}`;
    return this.axiosInstance
      .post(API_URLS.DOCUMENTOS, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((res) => res.data);
  }

  update(id, data) {
    const token = `Bearer ${session.getToken()}`;
    return this.axiosInstance
      .put(`${API_URLS.DOCUMENTOS}/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((res) => res.data);
  }

  delete(id) {
    const token = `Bearer ${session.getToken()}`;
    return this.axiosInstance
      .delete(`${API_URLS.DOCUMENTOS}/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => res.data);
  }

  getFiles(docId) {
    const token = `Bearer ${session.getToken()}`;
    return this.axiosInstance
      .get(API_URLS.ARQUIVOS, {
        headers: {
          Authorization: token,
        },
        params: {
          documento_id: docId,
        },
      })
      .then((res) => res.data);
  }

  getFile(docId) {
    const token = `Bearer ${session.getToken()}`;
    return this.axiosInstance
      .get(`${API_URLS.ARQUIVOS}/${docId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => res.data);
  }

  deleteFile(fileId) {
    const token = `Bearer ${session.getToken()}`;
    return this.axiosInstance
      .delete(`${API_URLS.ARQUIVOS}/${fileId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => res.data);
  }
  // FIXME: em implementação
  // sendFile(fileData) {
  //   return this.axiosInstance
  //     .post(API_URLS.ARQUIVOS, fileData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((res) => res.data)
  //     .catch((error) => error.message);
  // }
}

const DocumentosAPI = new AxiosDocumentosService(axiosInstance);

export default DocumentosAPI;
