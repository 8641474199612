import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const MenuCard = ({ children, title, image, buttonComponent, to }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4} lg={3}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={image || ""}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {children}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            component={buttonComponent}
            to={to}
          >
            Ver {title}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default MenuCard;
