import { API_URLS } from "config/constants";
import session from "services/storage/session-storage";
import axiosInstance from "./axios-instance";

class AxiosUsuariosService {
  constructor(instance) {
    this.axiosInstance = instance;
  }

  findAll({ page = 1, limit = 5, search }) {
    const token = `Bearer ${session.getToken()}`;
    const config = {
      headers: { Authorization: token },
      params: {
        page,
        limit,
        search,
      },
    };
    return this.axiosInstance
      .get(API_URLS.USUARIOS, config)
      .then((response) => response.data);
  }

  find(id) {
    const token = `Bearer ${session.getToken()}`;
    const config = {
      headers: { Authorization: token },
    };
    return this.axiosInstance
      .get(`${API_URLS.USUARIOS}/${id}`, config)
      .then((response) => response.data);
  }

  create(userObj) {
    const token = `Bearer ${session.getToken()}`;
    const config = {
      headers: { Authorization: token },
    };
    return this.axiosInstance
      .post(API_URLS.USUARIOS, { ...userObj }, config)
      .then((response) => response.data);
  }

  update(id, userObj) {
    const token = `Bearer ${session.getToken()}`;
    const config = {
      headers: { Authorization: token },
    };
    return this.axiosInstance
      .put(`${API_URLS.USUARIOS}/${id}`, { ...userObj }, config)
      .then((response) => response.data);
  }

  delete(id) {
    const token = `Bearer ${session.getToken()}`;
    const config = {
      headers: { Authorization: token },
    };
    return this.axiosInstance
      .delete(`${API_URLS.USUARIOS}/${id}`, config)
      .then((response) => response.data);
  }

  resetPassword(email) {
    const token = `Bearer ${session.getToken()}`;
    const config = {
      headers: { Authorization: token },
    };
    return this.axiosInstance
      .post(API_URLS.RESET_PASSWORD, { email }, config)
      .then((response) => response.data);
  }

  setNewPassword({ email, token, password }) {
    return this.axiosInstance
      .post(API_URLS.NEW_PASSWORD, { email, token, senha: password })
      .then((response) => response.data);
  }
}

const UsuariosAPI = new AxiosUsuariosService(axiosInstance);

export default UsuariosAPI;
