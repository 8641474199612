export const API_URLS = {
  BASE: process.env.REACT_APP_API_BASEURL,
  LOGIN: "/autenticacao",
  RESET_PASSWORD: "/esqueceu_senha",
  NEW_PASSWORD: "/resetar_senha",
  DOCUMENTOS: "/documentos",
  ARQUIVOS: "/arquivos",
  USUARIOS: "/usuarios",
};

export const ROUTE_PATH = {
  ROOT: "/",
  PAINEL: "/painel",
  LOGIN: "/login",
  RESET_SENHA: "/redefinir_senha",
  DOCUMENTOS: "/documentos",
  DOCUMENTO: "/documentos/:id",
  DOCUMENTO_EDITAR: "/documentos/:id/editar",
  USUARIOS: "/usuarios",
  USUARIO: "/usuarios/:id",
  USUARIOS_EDITAR: "/usuarios/:id/editar",
};
