import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = () => {
  return (
    <Paper>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={120}
      >
        <CircularProgress />
      </Box>
    </Paper>
  );
};

export default Loading;
