import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import ModalTemplate from "components/ModalTemplate";
import { useMessageContext } from "contexts/MessagesContext";
import UsuariosAPI from "services/api/axios-usuarios";
import { useHistory } from "react-router";

const NovoUsuario = ({ closeButtonAction, onSave, resetScreen }) => {
  const history = useHistory();
  const { 1: action } = useMessageContext();

  const [loading, setLoading] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");

  const resetScreenCb = useCallback(resetScreen, [resetScreen]);

  useEffect(() => {
    return () => {
      resetScreenCb();
    };
  }, [resetScreenCb]);

  const handleCreateUser = async (values) => {
    const { email, nome } = values;
    if (formik.values.email !== confirmEmail) {
      formik.setFieldError("email", "Os e-mails precisam ser iguais.");
    } else {
      try {
        setLoading(true);
        await UsuariosAPI.create({ email, nome });
        action.showToast({
          type: "success",
          content: "Usuário cadastrado com sucesso",
        });
        setLoading(false);
        closeButtonAction();
        history.push("/");
      } catch (error) {
        setLoading(false);
        action.showToast({
          type: "error",
          content: error?.message,
        });
        closeButtonAction();
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      nome: "",
    },
    onSubmit: handleCreateUser,
  });

  return (
    <ModalTemplate title="Novo usuário" onCloseModal={closeButtonAction}>
      <Grid component={Box} p={2} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            id="nome"
            name="nome"
            label="Nome"
            variant="outlined"
            value={formik.values.nome}
            onChange={formik.handleChange}
            fullWidth
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            label="E-mail de cadastro"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email) && formik.touched.email}
            fullWidth
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="emailConfirm"
            name="emailConfirm"
            label="Confirme o e-mail"
            variant="outlined"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            error={Boolean(formik.errors.email) && formik.touched.email}
            helperText={Boolean(formik.errors.email) && formik.errors.email}
            fullWidth
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography display="block" gutterBottom>
            Uma senha temporária será automaticamente gerada e enviada pelo
            e-mail cadastrado.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          component={Box}
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={loading}
          >
            Criar
          </Button>
        </Grid>
      </Grid>
    </ModalTemplate>
  );
};

export default NovoUsuario;
