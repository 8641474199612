// TODO: Verificar a necessidade de throwing de erro
const session = {
  setToken(token) {
    if (!token) return;
    return sessionStorage.setItem("_usr_token", JSON.stringify(token));
  },

  getToken() {
    const token = sessionStorage.getItem("_usr_token");
    if (!token) return;
    return token.replaceAll('"', "");
  },

  clearToken() {
    return sessionStorage.removeItem("_usr_token");
  },
};

export default session;
