import { createContext, useContext, useReducer, useRef } from "react";
import { actionBuilder } from "./action-builder";
import { reducer } from "./reducer";

export const initialState = {
  hasMessage: false,
  hasToast: false,
  message: { type: "info", content: "" },
  toast: { type: "success", content: "" },
};

const Context = createContext();

/**
 * Hook personalizado criado por Michael Nascimento
 * Não esquecer de passar o provider pra o aplicativo.
 * @example const [state, action] = useMessage();
 * @returns Array[state, actions]
 */
export const useMessageContext = () => {
  const context = useContext(Context);

  if (typeof context === "undefined") {
    throw new Error("useMessage must be used within a MessageContextProvider.");
  }

  return context;
};

export const MessageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useRef(actionBuilder(dispatch));
  return (
    <Context.Provider value={[state, actions.current]}>
      {children}
    </Context.Provider>
  );
};
