import { makeStyles } from "@material-ui/core";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
}));

const ModalTemplate = ({ title, children, onCloseModal }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper variant="elevation" elevation={5}>
        <Grid container direction="column" spacing={4} className={classes.grid}>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography component="h2" variant="h4">
                {title}
              </Typography>
              <IconButton color="primary" onClick={onCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Divider light />
        {children}
      </Paper>
    </Container>
  );
};

export default ModalTemplate;
