import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

import { ROUTE_PATH as RP } from "config/constants";

import Toolbar from "@material-ui/core/Toolbar";
import MenuDrawer from "components/MenuDrawer";
import Header from "components/Header";

import Painel from "pages/Painel";
import Documentos from "pages/Documentos";
import EditarDocumento from "pages/EditarDocumento";
import Usuarios from "pages/Usuarios";
import EditarUsuario from "pages/EditarUsuario";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  main: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const DashboardRouter = ({ logoutAction }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isUpSmScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (isUpSmScreen) {
      setOpenMenu(() => isUpSmScreen);
    }
  }, [isUpSmScreen]);

  return (
    <>
      <Header
        onToggleMenu={() => setOpenMenu((prevOpenMenu) => !prevOpenMenu)}
      />
      <MenuDrawer open={openMenu} logoutAction={logoutAction} />
      <div className={classes.main}>
        <Toolbar />
        <Switch>
          <Route exact path={RP.ROOT} children={<Redirect to="/login" />} />
          <Route exact path={RP.PAINEL} component={Painel} />
          <Route exact path={RP.DOCUMENTOS} component={Documentos} />
          <Route path={RP.DOCUMENTO_EDITAR} component={EditarDocumento} />
          <Route exact path={RP.USUARIOS} component={Usuarios} />
          <Route exact path={RP.USUARIOS_EDITAR} component={EditarUsuario} />
        </Switch>
      </div>
    </>
  );
};

export default DashboardRouter;
