import axios from "axios";

class AxiosIbgeService {
  constructor(url) {
    this.axiosInstance = axios.create({
      baseURL: "https://servicodados.ibge.gov.br/api/v1/localidades",
    });
  }

  fetchUfs() {
    return this.axiosInstance.get("/estados").then(({ data }) => {
      const dataToArray = data.map((entry) => {
        return entry.sigla;
      });
      return dataToArray;
    });
  }

  fetchMunicipios(uf) {
    return this.axiosInstance
      .get(`/estados/${uf}/municipios`)
      .then(({ data }) => {
        const dataToArray = data.map((entry) => {
          return entry.nome;
        });
        return dataToArray;
      });
  }
}

const IbgeAPI = new AxiosIbgeService();

export default IbgeAPI;
