import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import OSCPanelLogo from "../assets/images/logo-painel.png";

import LoginPageTemplate from "components/LoginPageTemplate";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    // width: "95vw",
    padding: theme.spacing(2),
    maxWidth: 540,
  },
}));

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginMode, setLoginMode] = useState("login");
  const classes = useStyles();

  const {
    handleLoginButton,
    handleRecoveryButton,
    loading,
    showAlert,
    message,
    onCloseAlert,
    alertType = "error",
  } = props;

  const handleCloseAlert = () => {
    if (onCloseAlert) onCloseAlert();
  };

  const loginHandler = () => {
    handleLoginButton({ email: username, senha: password });
  };

  const recoverPassHandler = () => {
    handleRecoveryButton(username);
  };

  const renderLoginMessage = () => {
    return (
      <Typography variant="subtitle1">
        {loginMode === "login" && <>Seja bem vindo! Digite suas credenciais:</>}
        {loginMode === "recover" && <>Digite o seu e-mail de acesso</>}
      </Typography>
    );
  };

  return (
    <>
      <LoginPageTemplate>
        <Grid
          container
          component={Paper}
          spacing={2}
          variant="outlined"
          className={classes.paper}
        >
          <Grid item xs={12} container justify="center">
            <Box py={2} display="flex" justifyContent="center">
              <img src={OSCPanelLogo} alt="Logo painel OSC Legal" />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="fullWidth" orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Box py={2} display="flex" justifyContent="center">
              <Typography variant="subtitle1">
                {renderLoginMessage()}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled={loading}
              id="email"
              label="E-mail de cadastro"
              variant="outlined"
              onChange={({ target }) => setUsername(target.value)}
              value={username}
              fullWidth
            />
          </Grid>

          <Fade in={loginMode !== "recover"}>
            <Grid item xs={12}>
              <TextField
                disabled={loading}
                id="senha"
                label="Senha"
                type="password"
                variant="outlined"
                onChange={({ target }) => setPassword(target.value)}
                value={password}
                fullWidth
              />
            </Grid>
          </Fade>

          <Grid item xs={12}>
            <Collapse in={showAlert}>
              <Alert
                severity={alertType}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleCloseAlert}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {message}
              </Alert>
            </Collapse>
          </Grid>

          <Grid item xs={12} container justify="space-between">
            {loginMode === "recover" ? (
              <Button
                disabled={loading}
                variant="contained"
                size="large"
                color="primary"
                onClick={recoverPassHandler}
              >
                {loading ? <>Carregando</> : <>Recuperar senha</>}
              </Button>
            ) : (
              <Button
                disabled={loading}
                variant="contained"
                size="large"
                color="primary"
                onClick={loginHandler}
              >
                {loading ? <>Carregando</> : <>Acessar o sistema</>}
              </Button>
            )}
            {loginMode === "login" && (
              <Button
                disabled={loading}
                variant="default"
                color="primary"
                onClick={() => setLoginMode("recover")}
              >
                Esqueci minha senha
              </Button>
            )}
            {loginMode === "recover" && (
              <Button
                disabled={loading}
                variant="default"
                color="primary"
                onClick={() => setLoginMode("login")}
              >
                Voltar
              </Button>
            )}
          </Grid>
        </Grid>
      </LoginPageTemplate>
    </>
  );
};

export default Login;
