import { API_URLS } from "config/constants";
import axiosInstance from "./axios-instance";
class AxiosAuthService {
  constructor(instance) {
    this.axiosInstance = instance;
  }

  authenticate(credentials) {
    return this.axiosInstance
      .post(API_URLS.LOGIN, credentials)
      .then((response) => response.data);
    // .catch((error) => error.message);
  }
}

const AuthAPI = new AxiosAuthService(axiosInstance);

export default AuthAPI;
