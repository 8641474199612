export const extrairUfRegistros = (arrayRegistros) => {
  if (!arrayRegistros) return;
  return Array.from(
    new Set(
      arrayRegistros
        .filter((registro) => registro.uf !== null)
        .map((registro) => registro.uf)
    )
  );
};

export const extrairMunicipioRegistros = (uf, arrayRegistros) => {
  if (!arrayRegistros || !uf) return;
  return Array.from(
    new Set(
      arrayRegistros
        .filter((registro) => registro.uf === uf)
        .filter((registro) => registro.municipio !== null)
        .map((registro) => registro.municipio)
    )
  );
};
