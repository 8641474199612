import axios from "axios";
import { API_URLS } from "config/constants";

const axiosInstance = axios.create({
  baseURL: API_URLS.BASE,
});

axiosInstance.interceptors.response.use(
  onSuccessResInterceptor,
  onFailureResInterceptor
);

function onSuccessResInterceptor(data) {
  return data;
}

function onFailureResInterceptor(response) {
  const { status, data } = response.response;
  return Promise.reject({
    status: status,
    message: data.message,
  });
}

export default axiosInstance;
